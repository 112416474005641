import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_UT_SEGUPHARMA_CONFIG_OV

export default class CalculoCuotaService {
  listarTasa () {
    return http.get(`${baseUrl}/calculo-cuota/listar-calculo-cuota`, {
      headers: {
        loading: true
      }
    })
  }

  paginate (params) {
    return http.get(`${baseUrl}/calculo-cuota/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  getTasa (id) {
    return http.get(`${baseUrl}/calculo-cuota/find/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  obtenerCuotaAnioNivel (params) {
    return http.get(`${baseUrl}/calculo-cuota/find`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  crearTasa (data) {
    return http.post(`${baseUrl}/calculo-cuota`, data, {
      headers: {
        loading: true
      }
    })
  }

  editarTasa (data) {
    return http.put(`${baseUrl}/calculo-cuota/${data.Id}`, data, {
      headers: {
        loading: true
      }
    })
  }

  eliminarTasa (id) {
    return http.delete(`${baseUrl}/calculo-cuota/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}
